import React from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, useTheme } from '@mui/material';

import { SENTENCE_BORDER_WIDTH } from '../../infra/constants';

import { useTag } from './useTag';

export interface ComponentProps {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  tag: string;
  word: string;
}

export const Tag = observer((props: ComponentProps) => {
  const { onMouseEnter, onMouseLeave, word } = props;
  const { handleClick, selected } = useTag(props);
  const { palette } = useTheme();

  return (
    <Box>
      <Box
        onClick={handleClick}
        paddingY="4px"
        paddingX="10px"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{
          border: `${SENTENCE_BORDER_WIDTH}px ${selected ? palette.error.main : palette.success.main} solid`,
          borderBottom: selected ? `${SENTENCE_BORDER_WIDTH}px ${palette.error.main} solid` : 0,
          backgroundColor: 'transparent',
          borderRadius: selected ? '100%' : 0,
          '&:hover': {
            cursor: 'pointer',
            borderColor: palette.error.main,
          },
        }}
      >
        <Typography
          padding={0}
          color="primary"
          fontWeight="light"
          variant="body2"
          sx={{
            fontWeight: 100,
          }}
        >
          {word}
        </Typography>

      </Box>
    </Box>
  );
});
