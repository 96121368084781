import React from 'react';
import { Box, Fade } from '@mui/material';
import { observer } from 'mobx-react';

import { ShizumItem } from '../ShizumItem';
import { Panner } from '../Navigation/Panner';
import { Assets } from '../../assets';

import { useTape } from './useTape';
import { TapeHeader } from './components/Header';
import { TapeFooter } from './components/Footer';
import './index.css';
// import { getTapeHeight } from '../../constants/ratios';

export const Tape = observer(() => {
  const {
    animateToX,
    containerRef,
    handleBack,
    handleMinusClick,
    handleMouseEnterShizum,
    handleMouseLeaveShizum,
    handleNextShizumClick,
    handlePanChange,
    handlePlusClick,
    handlePrevShizumClick,
    handleShizumClick,
    isFirst,
    isLast,
    inViewMode,
    inDiscoverMode,
    isInteractive,
    isSpotlight,
    itemHeight,
    itemWidth,
    shizums,
    shizumInSpotlight,
    shouldCompensateForZoom,
    zoomAnimation,
  } = useTape();

  const renderPrevButton = () => (
    <Fade in={inViewMode && !isFirst}>
      <Box zIndex={999} position="absolute" left={80} onClick={handlePrevShizumClick}>
        <img src={Assets.img.previous} alt="" height={48} width={48} />
      </Box>
    </Fade>
  );

  const renderNextButton = () => (
    <Fade in={inViewMode && !isLast}>
      <Box zIndex={999} position="absolute" right={80} onClick={handleNextShizumClick}>
        <img src={Assets.img.next} alt="" height={48} width={48} />
      </Box>
    </Fade>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      // height={getTapeHeight()}
    >
      <TapeHeader
        inViewMode={inViewMode}
        onBack={handleBack}
        onMinus={handleMinusClick}
        onPlus={handlePlusClick}
        shizum={shizumInSpotlight}
      />

      <Box
        ref={containerRef}
        flexGrow={1}
        display="flex"
        alignItems="center"
      >
        {renderPrevButton()}
        <Panner
          animateTo={animateToX}
          disable={inViewMode}
          drifting={inDiscoverMode}
          isZoomer
          checkOverflow={!isInteractive && inDiscoverMode}
          onChange={handlePanChange}
          shouldCompensateForZoom={shouldCompensateForZoom}
        >
          <Box
            display="flex"
            position="relative"
          >
            {shizums.map((shizum) => (
              <ShizumItem
                animation={zoomAnimation}
                key={shizum.id}
                isInteractive={isInteractive}
                shizum={shizum}
                height={itemHeight}
                width={itemWidth}
                onClick={handleShizumClick}
                onMouseEnter={handleMouseEnterShizum}
                onMouseLeave={handleMouseLeaveShizum}
                stopVideo={isSpotlight}
              />
            ))}
          </Box>
        </Panner>
        {renderNextButton()}
      </Box>

      <TapeFooter inViewMode={inViewMode} shizum={shizumInSpotlight} />
    </Box>
  );
});
