import { useCallback } from 'react';
import { store } from '../../stores/app';
import { TapeStatus } from '../../stores/infra/interfaces';
import { usePlayer } from '../Player/usePlayer';

export const useTopBar = () => {
  const { inDiscoverMode, inViewMode } = {
    inDiscoverMode: store.get.tapeStatus() === TapeStatus.DISCOVER,
    inViewMode: store.get.tapeStatus() === TapeStatus.VIEW,
  };

  const { pause, play } = usePlayer();

  const handleDiscoverClick = useCallback(() => {
    if (inViewMode) {
      pause();
      store.set.tapeStatus(TapeStatus.DISCOVER);
    }
  }, [inViewMode, pause]);

  const handleViewClick = useCallback(() => {
    if (inDiscoverMode) play();
  }, [inDiscoverMode, play]);

  return {
    handleDiscoverClick,
    handleViewClick,
    inDiscoverMode,
    inViewMode,
  };
};
