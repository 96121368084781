import React from 'react';
import ReactPlayer from 'react-player';
import { observer } from 'mobx-react';
import { Box, Fade } from '@mui/material';
import { animated } from 'react-spring';

import { Shizum } from '../../services/database/types';

import { useShizumItem } from './useShizumItem';
import './index.css';

const transitionTime = 10000;

export interface ComponentProps {
  animation: any;
  height: number;
  isInteractive: boolean;
  onClick?: (shizum: Shizum) => void;
  onMouseEnter?: (shizum: Shizum) => void;
  onMouseLeave?: (shizum: Shizum) => void;
  shizum: Shizum;
  stopVideo?: boolean;
  width: number;
}

export const ShizumItem = React.memo(observer((props: ComponentProps) => {
  const {
    animation, shizum,
  } = props;
  const {
    cartoonPlayerRef,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    inDiscoverMode,
    playing,
    playingCartoon,
    playerRef,
    selected,
    shouldUseOpacityAnimationValues,
    showCartoon,
  } = useShizumItem(props);

  const itemHeight = animation.height;
  const itemWidth = (shizum.portrait ? animation.portraitWidth : animation.width);

  return (
    <animated.div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      style={{
        width: itemWidth,
        height: itemHeight,
        opacity: inDiscoverMode ? 1 : (shouldUseOpacityAnimationValues ? animation.opacity : (selected ? 1 : 0)),
        position: 'relative',
      }}
    >
      <Fade in={!showCartoon} timeout={showCartoon ? transitionTime : 1000}>
        <Box>
          <ReactPlayer
            ref={playerRef}
            url={shizum.video.url}
            playing={playing}
            loop
            muted
            height="inherit"
            width="inherit"
          />
        </Box>
      </Fade>
      <Fade in={showCartoon} timeout={showCartoon ? transitionTime : 1000}>
        <Box position="absolute" top={0}>
          <ReactPlayer
            ref={cartoonPlayerRef}
            url={shizum.cartoonVideo.url}
            playing={playingCartoon}
            loop
            muted
            height="inherit"
            width="inherit"
          />
        </Box>
      </Fade>
    </animated.div>
  );
}));
