import React from 'react';
import {
  Box, Typography, useTheme,
} from '@mui/material';

import { observer } from 'mobx-react';

import { dateToDDMMYYYY, dateToHHMM } from '../../../../utils/parse/time';

import { Shizum } from '../../../../services/database/types';
import { DrawLine } from '../../../DrawLine';
import {
  HEADER_FOOTER_HEIGHT,
  SECTIONS_SPACING,
} from '../../infra/constants';

interface ComponentProps {
  inViewMode: boolean;
  shizum: Shizum | undefined;
}

export const TapeFooter = observer(({ inViewMode, shizum }: ComponentProps) => {
  const { palette, spacing } = useTheme();

  const renderShizumDetails = () => {
    const {
      artist, name, location, timestamp,
    } = shizum!;

    const dateTime = new Date(timestamp);

    return (
      <>
        <Typography fontWeight={100} color="primary">{`${artist} - ${name}`}</Typography>
        <Typography marginLeft={spacing(SECTIONS_SPACING)} color="error">*</Typography>
        <Typography marginLeft={spacing(SECTIONS_SPACING)} fontWeight={100} color="primary">{location?.name}</Typography>
        <Typography marginLeft={spacing(SECTIONS_SPACING)} color="error">*</Typography>
        <Typography marginLeft={spacing(SECTIONS_SPACING)} fontWeight={100} color="primary">{`${dateToDDMMYYYY(dateTime)}`}</Typography>
        <Typography marginLeft={spacing(SECTIONS_SPACING)} color="error">*</Typography>
        <Typography marginLeft={spacing(SECTIONS_SPACING)} fontWeight={100} color="primary">{`${dateToHHMM(dateTime)}`}</Typography>
      </>
    );
  };

  return (
    <>
      {inViewMode && <DrawLine offset={-4.5} />}
      <Box
        width="100%"
        display="flex"
        borderColor={palette.primary.main}
        paddingX={spacing(5)}
        height={HEADER_FOOTER_HEIGHT}
        alignItems="center"
      >
        {shizum && renderShizumDetails()}
      </Box>
    </>
  );
});
