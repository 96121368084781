import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import ReactPlayer from 'react-player';

import { TapeStatus } from '../../stores/infra/interfaces';
import { playTrack } from '../../utils/helpers/audio';
import { store } from '../../stores/app';

import { ComponentProps } from './index';

const timeToTransition = 10000;

export const useShizumItem = ({
  isInteractive,
  onClick,
  onMouseEnter,
  onMouseLeave,
  shizum,
  stopVideo,
}: ComponentProps) => {
  const { inViewMode, isPlaying, selected } = {
    inViewMode: store.get.tapeStatus() === TapeStatus.VIEW,
    isPlaying: store.get.isPlaying(),
    selected: store.get.currentShizum()?.id === shizum.id,
  };

  const [inHover, setInHover] = useState(false);
  const [showCartoon, setShowCartoon] = useState(false);

  const playerRef = useRef<ReactPlayer>(null);
  const cartoonPlayerRef = useRef<ReactPlayer>(null);
  const cartoonTimer = useRef<number>();

  const inDiscoverMode = !inViewMode;
  const playing = (inViewMode && selected && isPlaying) || (inDiscoverMode && (inHover || !stopVideo));
  const playingCartoon = inViewMode && selected && isPlaying;
  const shouldUseOpacityAnimationValues = !isInteractive && !selected;

  const handleClick = useCallback(() => {
    const isClickable = selected || inDiscoverMode;
    if (isClickable) {
      onClick?.(shizum);
    }
  }, [inDiscoverMode, onClick, selected, shizum]);

  const handleMouseEnter = useCallback(() => {
    setInHover(true);
    onMouseEnter?.(shizum);
  }, [onMouseEnter, shizum]);

  const handleMouseLeave = useCallback(() => {
    setInHover(false);
    onMouseLeave?.(shizum);
  }, [onMouseLeave, shizum]);

  useEffect(() => {
    if (inViewMode && selected) {
      playTrack(shizum.song.url);
      cartoonTimer.current = window.setTimeout(() => {
        setShowCartoon(true);
      }, timeToTransition);
    }
    if (inDiscoverMode || !selected) {
      window.clearTimeout(cartoonTimer.current);
      setShowCartoon(false);
    }
  }, [inDiscoverMode, inViewMode, selected, shizum]);

  useEffect(() => {
    if (inViewMode) {
      playerRef.current?.seekTo(0, 'seconds');
      cartoonPlayerRef.current?.seekTo(0, 'seconds');
    }
  }, [inViewMode]);

  return {
    cartoonPlayerRef,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    inDiscoverMode,
    inViewMode,
    playing,
    playingCartoon,
    playerRef,
    selected,
    shouldUseOpacityAnimationValues,
    showCartoon,
  };
};
