import React from 'react';
import {
  Box, Button, useTheme,
} from '@mui/material';
import { observer } from 'mobx-react';

import { DrawLine } from '../DrawLine';
import { BORDER_WIDTH } from '../TopBar/infra/constants';

import { ProgressBar } from './components/ProgressBar';
import { usePlayer } from './usePlayer';
import './index.css';
import { getPlayerHeight } from '../../constants/ratios';
import { PLAYER_HEIGHT } from './infra/constants';

export const Player = observer(() => {
  const { handlePlayPauseClick, isPlaying } = usePlayer();
  const { spacing, palette } = useTheme();
  const playerHeight = getPlayerHeight();

  return (
    <>
      <DrawLine offset={-4.5} />

      <Box
        display="flex"
        borderColor={palette.primary.main}
        height={playerHeight}
      >

        <Box display="flex" flexGrow={1} paddingLeft={spacing(4)}>
          <Box
            height="100%"
            width={PLAYER_HEIGHT}
            borderRight={BORDER_WIDTH}
            borderLeft={BORDER_WIDTH}
            borderColor={palette.primary.light}
          >
            <Button
              fullWidth
              disableRipple
              onClick={handlePlayPauseClick}
              sx={{
                height: '100%',
                textTransform: 'none',
                fontWeight: 100,
                '&:hover': {
                  backgroundColor: 'transparent',
                  fontWeight: 'light',
                },
              }}
            >
              {isPlaying ? 'Pause' : 'Play'}
            </Button>
          </Box>

          <Box
            display="flex"
            flexGrow={1}
            alignItems="center"
            paddingY={spacing(2)}
            paddingX={spacing(10)}
          >
            <ProgressBar />
          </Box>
        </Box>
      </Box>
    </>
  );
});
