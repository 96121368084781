const audio = new Audio();
audio.volume = 0.5;
audio.autoplay = false;

export const playTrack = (url: string) => {
  const currentSrc = audio.getAttribute('src');
  if (currentSrc !== url) {
    audio.setAttribute('src', url);
  }
  audio.play();
};

export const pauseTrack = () => {
  audio.pause();
};
