import { isDev } from '../utils/environment';

const SERVER_PREFIX = isDev() ? process.env.REACT_APP_SERVER_PREFIX : '/api';

export const endpoints = {
  airtable: {
    shizums: `${SERVER_PREFIX}/shizums?friends=true&locations=true`,
    shizum: (id: string) => `${SERVER_PREFIX}/shizums/${id}`,
  },
};
