import { debounce } from 'lodash';
import { store } from './app';
import { Filter } from './infra/interfaces';

export const applyFilters = debounce(() => {
  store.set.applyFilters();
}, 100);

export const applyFiltersAndSetFirst = debounce(() => {
  store.set.applyFiltersAndSetFirst();
}, 100);

export function addFilterAndApply(filter: Filter, predicate: any) {
  store.set.addFilter(filter, predicate);
  applyFilters();
}
export function addFilterApplyAndSetFirst(filter: Filter, predicate: any) {
  store.set.addFilter(filter, predicate);
  applyFiltersAndSetFirst();
}

export function removeFilterAndApply(filter: Filter) {
  store.set.removeFilter(filter);
  applyFilters();
}

export function clearFilters() {
  store.set.clearFilters();
}
