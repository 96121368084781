export const Assets = {
  img: {
    back: require('./img/back.png'),
    filter: require('./img/filter.png'),
    minus: require('./img/minus.png'),
    next: require('./img/next.png'),
    plus: require('./img/plus.png'),
    previous: require('./img/previous.png'),
  },
  svg: {
    line: require('./img/line.svg').default,
    lineTopBar: require('./img/line_topbar.svg').default,
    logo: require('./img/logo.svg').ReactComponent,
  },
};
