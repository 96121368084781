import React from 'react';
import { Box, Collapse } from '@mui/material';
import { observer } from 'mobx-react';

import { TopBar } from '../../components/TopBar';
import { TagFilter } from '../../components/TagFilter';
import { Player } from '../../components/Player';
import { Tape } from '../../components/Tape';

import { useDashboard } from './useDashboard';

export const DashboardPage = observer(() => {
  const {
    hasShizums,
    handleCollapseTransitionEnd,
    inDiscoveryMode,
  } = useDashboard();

  return (
    <Box
      overflow="hidden"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <TopBar />

      <Box height="100%" display="flex" flexDirection="column">
        <Collapse
          in={inDiscoveryMode}
          onTransitionEnd={handleCollapseTransitionEnd}
          timeout={400}
        >
          <TagFilter />
        </Collapse>

        {hasShizums && (
        <Tape />
        )}
      </Box>

      <Box width="100%">
        <Player />
      </Box>
    </Box>
  );
});
