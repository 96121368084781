import React from 'react';
import {
  Box, Button, Collapse, Fade, Typography, useTheme,
} from '@mui/material';
import { observer } from 'mobx-react';

import { Shizum } from '../../../../services/database/types';
import { DrawLine } from '../../../DrawLine';
import { BORDER_WIDTH, LOGO_HEIGHT } from '../../../TopBar/infra/constants';
import { Assets } from '../../../../assets';
import {
  BASE_TAPE_NAME,
  HEADER_FOOTER_HEIGHT,
  SECTIONS_SPACING,
} from '../../infra/constants';

import { useTapeHeader } from './useTapeHeader';

interface ComponentProps {
  inViewMode: boolean;
  onBack: () => void;
  onMinus: () => void;
  onPlus: () => void;
  shizum: Shizum | undefined;
}

export const TapeHeader = observer(({
  inViewMode, onBack, onMinus, onPlus, shizum,
}: ComponentProps) => {
  const { handleClearTagsClick, handleTagClick, tapeTags } = useTapeHeader();
  const { palette, spacing } = useTheme();

  const renderButton = (asset: string, cb: () => void) => (
    <Box
      width={HEADER_FOOTER_HEIGHT}
      height={HEADER_FOOTER_HEIGHT}
      display="flex"
      justifyContent="center"
      borderTop={BORDER_WIDTH}
      borderLeft={BORDER_WIDTH}
      borderColor={palette.primary.light}
    >
      <Button
        onClick={cb}
        disableRipple
        fullWidth
        color="primary"
        sx={{
          fontSize: '24px',
          opacity: 0.4,
          fontWeight: 100,
          '&:hover': {
            backgroundColor: 'transparent',
            fontWeight: 'light',
          },
        }}
      >
        {asset}
      </Button>
    </Box>
  );

  const renderShizumText = () => {
    const { tags, text } = shizum!;

    return text.split(' ').map((word, index) => {
      const lowerCasedWord = word.toLowerCase().replace(/[.,?!]/g, '');
      const isFirst = index === 0;
      const isTag = tags.includes(lowerCasedWord);
      const showTag = isTag && inViewMode;
      const handleClick = () => handleTagClick(lowerCasedWord);

      return (
        <Typography
          key={`${word}-${index}-tape-header`}
          marginLeft={isFirst ? spacing(SECTIONS_SPACING) : spacing(1.4)}
          fontWeight="light"
          color="primary"
          borderBottom={showTag ? 0.55 : undefined}
          borderColor={palette.error.main}
          onClick={showTag ? handleClick : undefined}
          sx={{
            '&:hover': {
              fontWeight: showTag ? 'medium' : 'light',
              cursor: showTag ? 'pointer' : undefined,
            },
          }}
        >
          {word}
        </Typography>
      );
    });
  };

  const renderTags = () => (
    <>
      <Typography marginLeft={spacing(3)} fontWeight={100} color="primary">{tapeTags}</Typography>
      <Typography marginLeft={spacing(3)} fontWeight={100} color="error">///</Typography>
    </>
  );

  return (
    <>
      <Box
        borderColor={palette.primary.light}
        width="100%"
        display="flex"
        justifyContent="space-between"
        height={HEADER_FOOTER_HEIGHT}
      >
        <Box
          display="flex"
          alignItems="center"
          paddingX={spacing(5)}
        >
          <Collapse in={inViewMode} orientation="horizontal">
            <Box
              onClick={onBack}
              marginRight={spacing(3)}
              sx={{ cursor: 'pointer' }}
            >
              <img src={Assets.img.back} alt="" height={30} width={30} />
            </Box>
          </Collapse>

          <Box display="flex" alignItems="center">
            <Typography position="relative" top={2} fontWeight={100} color="error">*</Typography>
            <Typography marginLeft={spacing(2)} fontWeight={100} color="primary">{BASE_TAPE_NAME}</Typography>
            <Typography position="relative" top={2} marginLeft={spacing(2)} fontWeight={100} color="error">*</Typography>
            <Typography marginLeft={spacing(2)} fontWeight={100} color="error">///</Typography>
          </Box>
          {tapeTags && renderTags()}
          {shizum
            ? renderShizumText() : (
              <Typography marginLeft={spacing(3)} fontWeight={100} color="error">
                --------------------------------------------------
              </Typography>
            )}
        </Box>

        <Fade in={!inViewMode}>
          <Box display="flex">
            <Box
              borderLeft={BORDER_WIDTH}
              borderTop={BORDER_WIDTH}
              borderColor={palette.primary.light}
            >
              <Button
                variant="text"
                disableRipple
                fullWidth
                onClick={handleClearTagsClick}
                sx={{
                  textTransform: 'none',
                  fontWeight: 100,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    fontWeight: 'light',
                  },
                }}
              >
                reset
              </Button>
            </Box>
            {renderButton('-', onMinus)}
            {renderButton('+', onPlus)}
            <Box
              width={LOGO_HEIGHT}
              borderLeft={BORDER_WIDTH}
              borderColor={palette.primary.light}
            />
          </Box>
        </Fade>

      </Box>
      <DrawLine offset={-4} />
    </>
  );
});
