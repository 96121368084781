import React from 'react';
import {
  Box, Typography,
} from '@mui/material';

import { Panner } from '../Navigation/Panner';
import { store } from '../../stores/app';

import { Sentence } from './components/Sentence';
import { useTagFilter } from './useTagFilter';
import { TAPE_MODIFIER_HEIGHT } from '../TapeModifier/infra/constants';
// import { getTagFilterHeight } from '../../constants/ratios';
// import { TAPE_MODIFIER_HEIGHT } from '../TapeModifier/infra/constants';

export function TagFilter() {
  const {
    containerRef, paddingY, spacing,
    // width,
  } = useTagFilter();

  // const shouldRenderOneLine = window.innerHeight < 1080;

  // const containerProps = shouldRenderOneLine ? {
  //   height: getTagFilterHeight(),
  //   boxSizing: 'border-box',
  // } : {
  //   display: 'flex',
  //   maxHeight: TAPE_MODIFIER_HEIGHT,
  // };

  // const innerContentProps = shouldRenderOneLine ? {
  //   height: getTagFilterHeight(),
  //   boxSizing: 'border-box',
  // } : {
  //   display: 'flex',
  //   marginX: spacing(4),
  //   flexWrap: width ? 'wrap' : undefined,
  //   width,
  // };

  const renderSentences = () => {
    const allShizums = store.get.allShizums();
    return allShizums.map((shizum, index) => (
      <Box key={shizum.id} display="flex" marginY={spacing(1)}>
        <Sentence shizum={shizum} />
        {index < allShizums.length - 1 && (
          <Box display="flex" alignItems="center" marginX={spacing(4)}>
            <Typography color="error">*</Typography>
          </Box>
        )}
      </Box>
    ));
  };

  return (
    <Box
      width="100%"
      paddingY={paddingY}
      height={TAPE_MODIFIER_HEIGHT}
      boxSizing="border-box"
    >

      <Panner shouldCompensateForZoom>
        <Box
          ref={containerRef}
          display="flex"
          marginX={spacing(4)}
        >
          {renderSentences()}
        </Box>
      </Panner>
    </Box>
  );
}
