import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';

import {
  DOT_SIZE, LEFT_OFFSET, LINE_WIDTH, TOP_OFFSET,
} from './infra/constants';
import { useProgressBar } from './useProgressBar';

export const ProgressBar = observer(() => {
  const { normalizedProgress } = useProgressBar();
  const { palette } = useTheme();

  const lineColor = palette.success.main;

  return (
    <Box
      position="relative"
      display="flex"
      flexGrow={1}
      height={1}
    >
      <Box
        position="absolute"
        display="flex"
        flexGrow={1}
        width="100%"
        top="50%"
        height={`${LINE_WIDTH}px`}
        bgcolor={lineColor}
      />

      <Box
        position="absolute"
        top={`calc(50% - ${TOP_OFFSET}px)`}
        left={`calc(${normalizedProgress}% - ${LEFT_OFFSET}px)`}
        width={DOT_SIZE}
        height={DOT_SIZE}
        bgcolor={palette.error.main}
        borderRadius="100%"
      />

      <Box
        display="flex"
        flexGrow={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box height={10} width={`${LINE_WIDTH}px`} bgcolor={lineColor} />
        <Typography fontWeight={100} color="primary" position="relative" top="38%" fontSize="0.75rem">2019</Typography>
        <Box height={10} width={`${LINE_WIDTH}px`} bgcolor={lineColor} />
        <Typography fontWeight={100} color="primary" position="relative" top="38%" fontSize="0.75rem">2020</Typography>
        <Box height={10} width={`${LINE_WIDTH}px`} bgcolor={lineColor} />
        <Typography fontWeight={100} color="primary" position="relative" top="38%" fontSize="0.75rem">2021</Typography>
        <Box height={10} width={`${LINE_WIDTH}px`} bgcolor={lineColor} />
        <Typography fontWeight={100} color="primary" position="relative" top="38%" fontSize="0.75rem">2022</Typography>
        <Box height={10} width={`${LINE_WIDTH}px`} bgcolor={lineColor} />
      </Box>
    </Box>
  );
});
