import { useCallback } from 'react';
import { ComponentProps } from './index';
import { addFilterAndApply, removeFilterAndApply } from '../../../../stores/actions';
import { Filter } from '../../../../stores/infra/interfaces';
import { store } from '../../../../stores/app';

export const useTag = ({ tag }: ComponentProps) => {
  const selected = store.get.selectedTags().has(tag);

  const handleClick = useCallback(() => {
    const currentTags = store.get.selectedTags();
    if (!selected) {
      store.set.addTag(tag);
      currentTags.add(tag);
      addFilterAndApply(Filter.TAGS, Array.from(currentTags));
    } else {
      store.set.removeTag(tag);
      currentTags.delete(tag);
      const isEmpty = currentTags.size === 0;
      if (isEmpty) {
        removeFilterAndApply(Filter.TAGS);
      } else {
        addFilterAndApply(Filter.TAGS, Array.from(currentTags));
      }
    }
  }, [selected, tag]);

  return {
    handleClick,
    selected,
  };
};
