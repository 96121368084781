import React from 'react';
import {
  BrowserRouter as Router, Routes, Route, Navigate,
} from 'react-router-dom';

import { DashboardPage } from '../../pages/Dashboard';
import { ShizumView } from '../../pages/ShizumView';

export function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/shizums/:id" element={<ShizumView />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  );
}
