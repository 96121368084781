import { useCallback, useEffect, useState } from 'react';

import { store } from '../../stores/app';
import { TapeStatus } from '../../stores/infra/interfaces';

export function useDashboard() {
  const [showTopBarBottomBorder, setShowTopBarBottomBorder] = useState(true);
  const { hasShizums, inDiscoveryMode } = {
    hasShizums: store.get.hasShizums(),
    inDiscoveryMode: store.get.tapeStatus() === TapeStatus.DISCOVER,
  };

  const handleCollapseTransitionEnd = useCallback(() => {
    if (!inDiscoveryMode) setShowTopBarBottomBorder(false);
  }, [inDiscoveryMode]);

  useEffect(() => {
    if (inDiscoveryMode) setShowTopBarBottomBorder(true);
  }, [inDiscoveryMode]);

  return {
    hasShizums,
    handleCollapseTransitionEnd,
    inDiscoveryMode,
    showTopBarBottomBorder,
  };
}
