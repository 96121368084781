import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1E08A7',
      light: '#1E08A766',
    },
    secondary: {
      main: '#FF9371',
      light: '#FF93711A',
    },
    warning: {
      main: '#FAF3F3',
    },
    error: {
      main: '#FF4848',
    },
    success: {
      main: '#4CD6BD',
      light: '#4CD6BD26',
    },
  },
  typography: {
    fontFamily: "'Roboto Mono'",
    body1: {
      fontSize: '0.875rem',
    },
    body2: {
      fontSize: '0.75rem',
    },
  },
});
