import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useSpring } from 'react-spring';
import { throttle } from 'lodash';

import { ComponentProps } from './index';
import { DriftingSpeed } from './infra/components';

export const usePanner = ({
  animateTo,
  checkOverflow,
  disable,
  drifting,
  driftingSpeed = DriftingSpeed.SLOW,
  isZoomer,
  onChange,
  shouldCompensateForZoom,
}: ComponentProps) => {
  const [inHover, setInHover] = useState(false);
  const [inAnimation, setInAnimation] = useState(false);
  const [panningOffset, setPanningOffset] = useState(0);

  const containerRef = useRef<HTMLDivElement>();
  const driftingInterval = useRef<number>();
  const resizeObserver = useRef<ResizeObserver>();

  const panAnimation = useSpring({
    left: -panningOffset,
    config: { duration: inAnimation ? 200 : 0 },
    onRest: () => {
      setInAnimation(false);
    },
  });

  const pan = useCallback((addition: number) => {
    setPanningOffset((prevPanningOffset) => {
      const contentWidth = containerRef.current?.clientWidth ?? 0;
      const newOffset = prevPanningOffset + addition;
      const isStartOverflow = newOffset < 0 || contentWidth < window.innerWidth;
      const isEndOverflow = (newOffset + window.innerWidth) > contentWidth;

      if (isStartOverflow) return 0;
      if (isEndOverflow) return contentWidth - window.innerWidth;

      return newOffset;
    });
  }, []);

  const updatePanOffset = throttle((evt: WheelEvent) => {
    const {
      deltaX,
      deltaY,
    } = evt;

    const shouldPan = !disable && (!!deltaX || shouldCompensateForZoom);

    if (shouldPan) {
      const addition = deltaX + (shouldCompensateForZoom ? 2 * deltaY : 0);
      pan(addition);
    }
  }, 100);

  const handleWheel = useCallback((evt: WheelEvent) => {
    evt.preventDefault();
    updatePanOffset(evt);
  }, [updatePanOffset]);

  const handleMouseEnter = useCallback(() => {
    setInHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setInHover(false);
  }, []);

  const removeDriftingInterval = useCallback(() => {
    window.clearInterval(driftingInterval.current);
  }, []);

  const setDriftingInterval = useCallback(() => {
    const addition = driftingSpeed === DriftingSpeed.SLOW ? 0.25 : 0.75;
    driftingInterval.current = window.setInterval(() => {
      setPanningOffset(((prevPanningOffset) => {
        const newPanningOffset = prevPanningOffset + addition;
        if (newPanningOffset > (containerRef.current?.clientWidth ?? 0) - window.innerWidth) {
          removeDriftingInterval();
          return prevPanningOffset;
        }
        return newPanningOffset;
      }));
    }, 30);
  }, [driftingSpeed, removeDriftingInterval]);

  useEffect(() => {
    const ref = containerRef.current;

    ref?.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      ref?.removeEventListener('wheel', handleWheel, { passive: false } as EventListenerOptions);
    };
  }, [handleWheel]);

  useEffect(() => {
    if (inHover || !drifting) {
      removeDriftingInterval();
    } else {
      setDriftingInterval();
    }
  }, [drifting, inHover, setDriftingInterval, removeDriftingInterval]);

  useEffect(() => {
    if (animateTo !== undefined) {
      setPanningOffset((prevPanningOffset) => {
        setInAnimation(animateTo !== prevPanningOffset);
        return animateTo!;
      });
    }
  }, [animateTo]);

  useEffect(() => {
    onChange?.(panningOffset);
  }, [panningOffset, onChange]);

  useEffect(() => {
    if (containerRef.current && isZoomer && checkOverflow) {
      resizeObserver.current = new ResizeObserver(() => {
        if (!inAnimation) {
          const contentWidth = containerRef.current?.clientWidth ?? 0;
          const isStartOverflow = panningOffset < 0;
          const isEndOverflow = (panningOffset + window.innerWidth) > contentWidth;

          if (isStartOverflow) {
            setPanningOffset(0);
            return;
          }
          if (isEndOverflow) setPanningOffset(contentWidth - window.innerWidth);
        }
      });
      resizeObserver.current.observe(containerRef.current);

      return () => resizeObserver.current?.disconnect();
    }
  }, [isZoomer, panningOffset, checkOverflow, inAnimation]);

  return {
    containerRef,
    handleMouseEnter,
    handleMouseLeave,
    inAnimation,
    panAnimation,
    panningOffset,
  };
};
