export function formatSecondsToTime(number: number): string {
  const roundedNumber = Math.floor(number);
  const time = roundedNumber / 60;
  const min = Math.floor(time);
  const sec = Math.round((time - min) * 60);
  const minStr = `${min}`.padStart(2, '0');
  const secStr = `${sec}`.padStart(2, '0');
  return `${minStr}:${secStr}`;
}

export function dateToDDMMYYYY(date: Date) {
  const dd = date.getDay().toString().padStart(2, '0');
  const mm = date.getDay().toString().padStart(2, '0');
  const yyyy = date.getFullYear();
  return `${dd}.${mm}.${yyyy}`;
}

export function dateToHHMM(date: Date) {
  const hh = date.getHours().toString().padStart(2, '0');
  const mm = date.getMinutes().toString().padStart(2, '0');
  return `${hh}:${mm}`;
}
