import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getShizum } from '../../services/database/get-shizums';
import { store } from '../../stores/app';

export function useShizumView() {
  const { id: shizumId } = useParams();
  const [shizum, setShizum] = useState(shizumId ? store.get.shizum(shizumId) : undefined);

  useEffect(() => {
    async function getShizumAndUpdateState() {
      const fetchedShizum = await getShizum(shizumId!);
      setShizum(fetchedShizum);
    }
    if (!shizum) {
      getShizumAndUpdateState();
    }
  }, [shizum, shizumId]);

  return {
    shizum,
  };
}
