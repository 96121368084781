import { useEffect } from 'react';

import { getShizums } from './services/database/get-shizums';
import { store } from './stores/app';

export function useApp() {
  useEffect(() => {
    async function getDataAndUpdateState() {
      const [
        { shizums, friends, locationsNames },
      ] = await Promise.all([getShizums()]);

      store.set.allShizums(shizums);
      store.set.friends(friends);
      store.set.locationsNames(locationsNames);
    }
    getDataAndUpdateState();
  }, []);
}
