import { Method, NetworkParams } from './types';

export async function fetch<R>(url: string, params: NetworkParams = {}) {
  const { method = Method.GET } = params;
  const body = params.body ? JSON.stringify(params.body) : undefined;
  const requestParams: RequestInit = {
    method: method,
    body: body,
    headers: params.headers,
  };

  const res = await global.fetch(url, requestParams);
  const responseBody = await res.json() as R;

  return responseBody;
}
