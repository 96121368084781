import React from 'react';
import { Box, ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react';

import { AppRouter } from './components/AppRouter';
import { getFontPercentage } from './constants/ratios';
import { useApp } from './useApp';
import { theme } from './styles/theme';

function App() {
  useApp();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ fontSize: getFontPercentage(), height: '100vh' }}>
        <AppRouter />
      </Box>
    </ThemeProvider>
  );
}

export default observer(App);
