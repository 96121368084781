import { useCallback, useEffect } from 'react';
import { first } from 'lodash';

import { TapeStatus } from '../../stores/infra/interfaces';
import { pauseTrack, playTrack } from '../../utils/helpers/audio';
import { store } from '../../stores/app';

export const usePlayer = () => {
  const { inDiscoverMode, isPlaying, inViewMode } = {
    inDiscoverMode: store.get.tapeStatus() === TapeStatus.DISCOVER,
    isPlaying: store.get.isPlaying(),
    inViewMode: store.get.tapeStatus() === TapeStatus.VIEW,
  };

  const pause = useCallback(() => {
    pauseTrack();
    store.set.stopPlaying();
  }, []);

  const play = useCallback(() => {
    const currentShizum = store.get.currentShizum();
    const prevShizum = store.get.prevShizum();
    const firstShizum = first(store.get.shizums());

    const shizum = currentShizum ?? prevShizum ?? firstShizum;
    store.set.currentShizum(shizum);
    store.set.tapeStatus(TapeStatus.VIEW);
    if (shizum) playTrack(shizum.song.url);
    store.set.startPlaying();
  }, []);

  const handlePlayPauseClick = useCallback(() => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  }, [isPlaying, pause, play]);

  useEffect(() => {
    if (inViewMode) {
      play();
    }
  }, [inViewMode, play]);

  useEffect(() => {
    if (inDiscoverMode) {
      pause();
    }
  }, [inDiscoverMode, pause]);

  return {
    handlePlayPauseClick,
    pause,
    play,
    isPlaying,
  };
};
