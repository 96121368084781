import React from 'react';
import { Box } from '@mui/material';

import { Assets } from '../../assets';

interface ComponentProps {
  isTopBarLine?: boolean
  offset?: number
}

export function DrawLine({ offset, isTopBarLine }: ComponentProps) {
  return (
    <Box position="relative" zIndex={999}>
      <img
        src={isTopBarLine ? Assets.svg.lineTopBar : Assets.svg.line}
        alt=""
        width="100%"
        style={{ position: 'absolute', top: offset }}
      />
    </Box>
  );
}
