import {
  useEffect, useRef, useState,
} from 'react';
import { useTheme } from '@mui/material';

export const useTagFilter = () => {
  const { spacing } = useTheme();
  const paddingY = spacing(4);

  const [width, setWidth] = useState<number>();

  const containerRef = useRef<HTMLDivElement>();
  const hasSet = useRef(false);

  useEffect(() => {
    const offsetWidth = containerRef.current?.offsetWidth ?? 0;
    if (!hasSet.current && offsetWidth > 0) {
      setWidth(offsetWidth / 2.65);
      hasSet.current = true;
    }
  }, []);

  return {
    containerRef,
    paddingY,
    spacing,
    width,
  };
};
