import { fetch } from '../../utils/network';
import { endpoints } from '../../constants/endpoints';

import { Shizum, ShizumsFriendsLocationsSources } from './types';

export function getShizums(): Promise<ShizumsFriendsLocationsSources> {
  return fetch<ShizumsFriendsLocationsSources>(endpoints.airtable.shizums);
}

export function getShizum(id: string): Promise<Shizum> {
  return fetch<Shizum>(endpoints.airtable.shizum(id));
}
