import {
  configure, entries, makeObservable, observable,
} from 'mobx';
import { first, values } from 'lodash';

import { Shizum } from '../services/database/types';

import { AppliedFilters, Filter, TapeStatus } from './infra/interfaces';
import { filters } from './infra/constants';

configure({
  enforceActions: 'never',
});

class Store {
  private static getClearedFilters() {
    const clearedFilters: Partial<AppliedFilters> = {};
    values(Filter).forEach((filter) => {
      clearedFilters[filter] = { apply: false };
    });
    return clearedFilters as AppliedFilters;
  }

  private allShizums: Shizum[];
  private appliedFilters: AppliedFilters;
  private currentShizum: Shizum | undefined;
  private currentShizumIndex: number;
  private filteredShizums: Shizum[];
  private friends: string[];
  private isPlaying: boolean;
  private locationsNames: string[];
  private numberOfPortraits: number;
  private panProgress: number;
  private prevShizum: Shizum | undefined;
  private selectedTags: Set<string>;
  private sources: string[];
  private tapeStatus: TapeStatus;

  constructor() {
    this.allShizums = [];
    this.appliedFilters = Store.getClearedFilters();
    this.currentShizum = undefined;
    this.currentShizumIndex = -1;
    this.filteredShizums = [];
    this.friends = [];
    this.isPlaying = false;
    this.locationsNames = [];
    this.numberOfPortraits = 0;
    this.panProgress = 0;
    this.prevShizum = undefined;
    this.selectedTags = new Set();
    this.sources = [];
    this.tapeStatus = TapeStatus.DISCOVER;

    const annotations = {
      allShizums: observable,
      appliedFilters: observable,
      currentShizum: observable,
      currentShizumIndex: observable,
      panProgress: observable,
      filteredShizums: observable,
      friends: observable,
      isPlaying: observable,
      locationsNames: observable,
      sources: observable,
      selectedTags: observable,
      tapeStatus: observable,
      get: observable,
      set: observable,
    };

    makeObservable(this, annotations);
  }

  get = {
    allShizums: () => this.allShizums,
    appliedFilters: () => this.appliedFilters,
    currentShizum: () => this.currentShizum,
    currentShizumIndex: () => this.currentShizumIndex,
    numberOfPortraits: () => this.numberOfPortraits,
    numberOfAllPortraits: () => this.getNumberOfAllPortraits(),
    friends: () => this.friends,
    hasShizums: () => this.allShizums.length > 0,
    isCurrentShizumFirst: () => this.currentShizumIndex === 0,
    isCurrentShizumLast: () => this.currentShizumIndex === this.filteredShizums.length - 1,
    isFiltering: () => values(this.appliedFilters).some(({ apply }) => apply),
    isPlaying: () => this.isPlaying,
    locationsNames: () => this.locationsNames,
    panProgress: () => this.panProgress,
    prevShizum: () => this.prevShizum,
    shizums: () => this.filteredShizums,
    shizum: (shizumId: string) => this.allShizums.find((it) => it.id === shizumId),
    selectedTags: () => this.selectedTags,
    sources: () => this.sources,
    tapeStatus: () => this.tapeStatus,
  };

  set = {
    allShizums: (shizums: Shizum[]) => {
      this.allShizums = shizums;
      if (!this.get.isFiltering()) {
        this.filteredShizums = shizums;
      }
    },
    applyFilters: () => {
      this.filteredShizums = this.allShizums
        .filter((shizum: Shizum) => entries(this.appliedFilters)
          .every(([key, value]) => !value.apply || filters[key as Filter](shizum, value.predicate)));

      this.updateCurrentShizumIndex();
    },
    applyFiltersAndSetFirst: () => {
      this.filteredShizums = this.allShizums
        .filter((shizum: Shizum) => entries(this.appliedFilters)
          .every(([key, value]) => !value.apply || filters[key as Filter](shizum, value.predicate)));

      this.currentShizum = first(this.filteredShizums);
      this.updateCurrentShizumIndex();
    },
    addFilter: (filter: Filter, predicate: any) => {
      this.appliedFilters[filter] = { apply: true, predicate };
    },
    addTag: (tag: string) => {
      this.selectedTags.add(tag);
    },
    addOnlyTag: (tag: string) => {
      this.selectedTags = new Set([tag]);
    },
    currentShizum: (shizum: Shizum | undefined) => {
      this.currentShizum = shizum;
      this.updateCurrentShizumIndex();
    },
    panProgress: (progress: number) => {
      this.panProgress = progress;
    },
    firstShizum: () => {
      this.currentShizum = this.filteredShizums?.[0];
    },
    stopPlaying: () => {
      this.isPlaying = false;
    },
    startPlaying: () => {
      this.isPlaying = true;
    },
    nextShizum: () => {
      if (this.currentShizum !== undefined) {
        const currentShizumIndex = this.get.currentShizumIndex();
        const isLast = currentShizumIndex === this.filteredShizums.length - 1;
        const shizum = isLast ? undefined : this.filteredShizums[currentShizumIndex + 1];
        this.currentShizum = shizum;
        this.prevShizum = this.currentShizum;
        this.updateCurrentShizumIndex();
      }
    },
    prevShizum: () => {
      if (this.currentShizum !== undefined) {
        const currentShizumIndex = this.get.currentShizumIndex();
        const isFirst = currentShizumIndex === 0;
        const shizum = isFirst ? undefined : this.filteredShizums[currentShizumIndex - 1];
        this.prevShizum = this.currentShizum;
        this.currentShizum = shizum;
        this.updateCurrentShizumIndex();
      }
    },
    friends: (friends: string[]) => {
      this.friends = friends;
    },
    locationsNames: (locationsNames: string[]) => {
      this.locationsNames = locationsNames;
    },
    sources: (sources: string[]) => {
      this.sources = sources;
    },
    removeFilter: (filter: Filter) => {
      this.appliedFilters[filter] = { apply: false };
    },
    removeTag: (tag: string) => {
      this.selectedTags.delete(tag);
    },
    removeAllTags: () => {
      this.selectedTags = new Set();
    },
    clearFilters: () => {
      this.appliedFilters = Store.getClearedFilters();
    },
    tapeStatus: (status: TapeStatus) => {
      this.tapeStatus = status;
      this.prevShizum = this.currentShizum;
    },
  };

  private updateCurrentShizumIndex() {
    this.currentShizumIndex = this.currentShizum
      ? this.filteredShizums.findIndex((it) => it.id === this.currentShizum?.id)
      : -1;

    let numberOfPortraits = 0;
    this.filteredShizums.slice(0, this.currentShizumIndex).forEach(({ portrait }) => {
      if (portrait) numberOfPortraits += 1;
    });

    this.numberOfPortraits = numberOfPortraits;
  }

  private getNumberOfAllPortraits() {
    let numberOfPortraits = 0;
    this.filteredShizums.forEach(({ portrait }) => {
      if (portrait) numberOfPortraits += 1;
    });

    return numberOfPortraits;
  }
}

export const store = new Store();
