import React from 'react';
import { Box, useTheme } from '@mui/material';

import { observer } from 'mobx-react';

import { ModifierButton } from '../ModifierButton';
import { Assets } from '../../assets';

import {
  BORDER_WIDTH, LOGO_HEIGHT,
} from './infra/constants';
import { useTopBar } from './useTopBar';
import { DrawLine } from '../DrawLine';

export const TopBar = observer(() => {
  const {
    handleDiscoverClick, handleViewClick, inDiscoverMode, inViewMode,
  } = useTopBar();
  const { palette } = useTheme();

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        height={LOGO_HEIGHT}
        borderColor={palette.primary.main}
      >
        <Box
          height="100%"
          borderRight={BORDER_WIDTH}
          borderColor={palette.primary.light}
        >
          <Assets.svg.logo style={{ maxHeight: '100%' }} />
        </Box>

        <Box>
          <Box
            display="flex"
            height="100%"
          >
            <ModifierButton
              borderLeft
              label="Discover"
              selected={inDiscoverMode}
              onClick={handleDiscoverClick}
            />
            <ModifierButton
              label="View"
              selected={inViewMode}
              onClick={handleViewClick}
            />
            <Box
              width={LOGO_HEIGHT}
            />

          </Box>
        </Box>
      </Box>

      <DrawLine isTopBarLine offset={-7} />
    </>
  );
});
