import { useCallback } from 'react';

import { store } from '../../../../stores/app';
import { addFilterApplyAndSetFirst, removeFilterAndApply } from '../../../../stores/actions';
import { Filter } from '../../../../stores/infra/interfaces';

export const useTapeHeader = () => {
  const { selectedTags } = {
    selectedTags: Array.from(store.get.selectedTags()).slice(0, 5),
  };

  const handleTagClick = useCallback((tag: string) => {
    store.set.addOnlyTag(tag);
    addFilterApplyAndSetFirst(Filter.TAGS, Array.from([tag]));
  }, []);

  const handleClearTagsClick = useCallback(() => {
    store.set.removeAllTags();
    removeFilterAndApply(Filter.TAGS);
  }, []);

  const tapeTags = selectedTags.join(' / ');

  return {
    handleClearTagsClick,
    handleTagClick,
    tapeTags,
  };
};
