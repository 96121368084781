import React from 'react';
import { Box, Button, useTheme } from '@mui/material';

import { SelectionDot } from '../SelectionDot';
import { BORDER_WIDTH } from '../TopBar/infra/constants';

import { BUTTON_WIDTH } from './infra/constants';

export interface ComponentProps {
  borderLeft?: boolean;
  label: string;
  onClick?: () => void;
  selected?: boolean
}

export function ModifierButton({
  borderLeft, label, onClick, selected,
}: ComponentProps) {
  const { palette } = useTheme();

  return (
    <Box>
      <Box
        width={BUTTON_WIDTH}
        height="calc(100% - 1.5px)"
        display="flex"
        justifyContent="center"
        borderRight={BORDER_WIDTH}
        borderLeft={borderLeft ? BORDER_WIDTH : undefined}
        borderColor={palette.primary.light}
      >
        <Button
          variant="text"
          onClick={onClick}
          fullWidth
          disableRipple
          disableFocusRipple
          sx={{
            textTransform: 'none',
            fontWeight: 100,
            fontSize: '0.75rem',
            '&:hover': {
              backgroundColor: 'transparent',
              fontWeight: 'light',
            },
          }}
        >
          {label}
        </Button>
      </Box>
      {selected && <SelectionDot />}
    </Box>
  );
}
