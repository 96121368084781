import React from 'react';
import { Box } from '@mui/material';
import { animated } from 'react-spring';

import { usePanner } from './usePanner';
import { DriftingSpeed } from './infra/components';

export interface ComponentProps {
  animateTo?: number;
  checkOverflow?: boolean,
  disable?: boolean;
  drifting?: boolean;
  driftingSpeed?: DriftingSpeed,
  isZoomer?: boolean,
  onChange?: (offset: number) => void;
  shouldCompensateForZoom?: boolean,
}

// eslint-disable-next-line react/function-component-definition
export const Panner: React.FC<ComponentProps> = (props) => {
  const { children } = props;
  const {
    containerRef, handleMouseEnter, handleMouseLeave, panAnimation,
  } = usePanner(props);

  return (
    <Box
      ref={containerRef}
      width="fit-content"
      position="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      display="flex"
      alignItems="center"
      height="100%"
    >
      <animated.div
        style={{
          position: 'relative',
          left: panAnimation.left,
        }}
      >
        {children}
      </animated.div>
    </Box>
  );
};
