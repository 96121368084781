import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import { Shizum } from '../../../../services/database/types';
import { Tag } from '../Tag';
import { SENTENCE_BORDER_WIDTH } from '../../infra/constants';

import { useSentence } from './useSentence';

export interface ComponentProps {
  closeTapeModifier?: () => void;
  shizum: Shizum;
}

export function Sentence(props: ComponentProps) {
  const { shizum } = props;
  const {
    isHoverTag, handleClick, handleMouseEnterTag, handleMouseLeaveTag, words,
  } = useSentence(props);
  const { palette, spacing } = useTheme();

  const renderWords = () => words.map((word, index) => (
    <Box
      key={`${word}-${index}`}
      marginX="5px"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      {shizum.tags.includes(word.toLowerCase().replace(/[,.?!]/g, ''))
        ? (
          <Tag
            onMouseEnter={handleMouseEnterTag}
            onMouseLeave={handleMouseLeaveTag}
            word={word}
            tag={word.toLowerCase()}
          />
        )
        : (
          <Typography
            paddingX="10px"
            position="relative"
            color="primary"
            fontWeight={100}
            variant="body2"
          >
            {word}
          </Typography>
        )}
    </Box>
  ));

  return (
    <Box
      display="flex"
      borderBottom={SENTENCE_BORDER_WIDTH}
      borderColor={palette.success.main}
      paddingX={spacing(1)}
      marginY={spacing(1.5)}
      onClick={handleClick}
      sx={{
        '&:hover': {
          backgroundColor: !isHoverTag ? palette.warning.main : undefined,
          cursor: 'pointer',
        },
      }}
    >
      {renderWords()}
    </Box>
  );
}
