import { Shizum } from '../../services/database/types';

export enum Filter {
  FRIENDS = 'friends',
  LOCATIONS = 'locations',
  SEARCH = 'search',
  SHIZUM = 'shizum',
  TAGS = 'tags',
  TIME = 'time',
}

export type FilterFunctions = { [filter in Filter]: (shizum: Shizum, predicate: any) => boolean };

export type AppliedFilters = { [filter in Filter]: { apply: boolean; predicate?: any; } };

export enum TapeStatus {
  DISCOVER,
  VIEW,
}
