import { words as toWords } from 'lodash';
import { useCallback, useState } from 'react';

import { ComponentProps } from './index';
import { store } from '../../../../stores/app';
import { TapeStatus } from '../../../../stores/infra/interfaces';

export const useSentence = ({ closeTapeModifier, shizum }: ComponentProps) => {
  const { text } = shizum;
  const [isHoverTag, setIsHoverTag] = useState(false);

  const words = toWords(text, /[^, ]+/g);

  const handleClick = useCallback(() => {
    if (!isHoverTag) {
      store.set.currentShizum(shizum);
      const tapeStatus = store.get.tapeStatus();
      if (tapeStatus === TapeStatus.VIEW) {
        store.set.tapeStatus(TapeStatus.DISCOVER);
      } else {
        store.set.tapeStatus(TapeStatus.VIEW);
      }
      closeTapeModifier?.();
    }
  }, [closeTapeModifier, isHoverTag, shizum]);

  const handleMouseEnterTag = useCallback(() => {
    setIsHoverTag(true);
  }, []);

  const handleMouseLeaveTag = useCallback(() => {
    setIsHoverTag(false);
  }, []);

  return {
    isHoverTag,
    handleClick,
    handleMouseEnterTag,
    handleMouseLeaveTag,
    words,
  };
};
