import { store } from '../../../../stores/app';

export const useProgressBar = () => {
  const { progress } = {
    progress: store.get.panProgress(),
  };

  const normalizedProgress = (
    progress < 0
      ? 0
      : progress > 100
        ? 100
        : progress
  );

  return {
    normalizedProgress,
  };
};
