import React from 'react';
import { Container, Typography } from '@mui/material';
import { useShizumView } from './useShizumView';

export function ShizumView() {
  const { shizum } = useShizumView();

  return (
    <Container>
      {shizum
        ? (
          <Typography>
            Shizum View:
            {' '}
            {shizum.name}
          </Typography>
        )
        : <Typography>Loading...</Typography>}
    </Container>
  );
}
