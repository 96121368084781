import { toLower, words } from 'lodash';

import { FilterTimes } from '../../components/AdvancedFilterShizums/infra/interfaces';

import { FilterFunctions } from './interfaces';

function isHourMatchTime(time: FilterTimes, hour: number) {
  switch (time) {
    case FilterTimes.MORNING: return hour >= 4 && hour < 11;
    case FilterTimes.NOON: return hour >= 11 && hour < 18;
    case FilterTimes.EVENING: return hour >= 18 && hour < 22;
    case FilterTimes.NIGHT: return hour >= 22 || hour < 4;
    default: return true;
  }
}

export const filters: FilterFunctions = {
  friends(shizum, selectedFriends: string[]) {
    const friends = toLower(shizum.friends.join(','));
    return !!selectedFriends.find((selectedFriend) => friends.includes(selectedFriend.toLowerCase()));
  },

  locations({ location }, selectedLocations: string[]) {
    return !!selectedLocations
      .find((selectedLocation) => selectedLocation.toLowerCase() === location?.name.toLowerCase());
  },

  search({ name, artist }, searchTerm: string) {
    const nameMatch = name.toLowerCase().includes(searchTerm.toLowerCase());
    const artistMatch = artist.toLowerCase().includes(searchTerm.toLowerCase());
    return nameMatch || artistMatch;
  },

  shizum({ id }, shizumId: string) {
    return id === shizumId;
  },

  tags({ text }, selectedTags: string[]) {
    for (let i = 0; i < selectedTags.length; i += 1) {
      const currentTag = selectedTags[i];
      if (words(text.toLowerCase()).includes(currentTag)) {
        return true;
      }
    }
    return false;
  },

  time({ timestamp }, selectedTimes: FilterTimes[]) {
    const date = new Date(timestamp);
    let isTimeMatch = false;
    for (const time of selectedTimes) {
      const hour = date.getUTCHours();
      isTimeMatch ||= isHourMatchTime(time, hour);
    }
    return isTimeMatch;
  },
};
