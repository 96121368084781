import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { throttle } from 'lodash';
import { useSpring } from 'react-spring';

import { Shizum } from '../../services/database/types';
import { TapeStatus } from '../../stores/infra/interfaces';
import { store } from '../../stores/app';
import { TAPE_MODIFIER_HEIGHT } from '../TapeModifier/infra/constants';

import { usePrev } from '../../utils/hooks/usePrev';
import { pauseTrack, playTrack } from '../../utils/helpers/audio';

import { MID_ITEM_WIDTH, MIN_VIDEO_WIDTH, VIDEO_RATIO } from './infra/constants';

const minItemWidth = MIN_VIDEO_WIDTH;
const maxItemAddition = TAPE_MODIFIER_HEIGHT * VIDEO_RATIO;

export const useTape = () => {
  const {
    currentShizum, isFirst, isLast, numberOfShizums, shizums, tapeStatus,
  } = {
    currentShizum: store.get.currentShizum(),
    numberOfShizums: store.get.shizums().length,
    shizums: store.get.shizums(),
    tapeStatus: store.get.tapeStatus(),
    isFirst: store.get.isCurrentShizumFirst(),
    isLast: store.get.isCurrentShizumLast(),
  };

  const [hoveredShizum, setHoveredShizum] = useState<Shizum | undefined>();
  const [animateToX, setAnimateToX] = useState<number>();
  const [itemWidth, setItemWidth] = useState(0);
  const [isSpotlight, setIsSpotlight] = useState(false);
  const [isInteractive, setIsInteractive] = useState(true);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const hoverTimeout = useRef<number>();

  const prevTapeStatus = usePrev(tapeStatus);

  const maxItemHeight = containerRef.current?.clientHeight ?? 0;
  const maxItemWidth = maxItemHeight * VIDEO_RATIO;

  const inViewMode = tapeStatus === TapeStatus.VIEW;
  const inDiscoverMode = tapeStatus === TapeStatus.DISCOVER;
  const itemHeight = itemWidth / VIDEO_RATIO;
  const shouldCompensateForZoom = itemWidth < maxItemWidth && itemWidth > minItemWidth;
  const shizumInSpotlight = inViewMode ? currentShizum : hoveredShizum;
  const portraitItemWidth = (itemWidth / VIDEO_RATIO) / VIDEO_RATIO;
  const numberOfAllPortraits = store.get.numberOfAllPortraits();
  const numberOfAllLandscapes = numberOfShizums - numberOfAllPortraits;
  const maxTapeWidth = numberOfAllPortraits * portraitItemWidth + numberOfAllLandscapes * itemWidth;

  const zoomAnimation = useSpring({
    to: {
      height: itemHeight,
      width: itemWidth,
      portraitWidth: itemHeight / VIDEO_RATIO,
      opacity: inViewMode ? 0 : 1,
      config: { duration: 500 },
    },
    from: {
      opacity: inViewMode ? 1 : 0,
    },
  });

  useEffect(() => {
    if (inDiscoverMode) {
      setItemWidth(MID_ITEM_WIDTH);
      setAnimateToX(0);
    }
  }, [numberOfShizums]);

  const zoom = useCallback((addition: number, isZoomingIn: boolean) => {
    setItemWidth((prevItemWidth) => (
      isZoomingIn
        ? Math.min(prevItemWidth + addition, maxItemWidth)
        : Math.max(minItemWidth, prevItemWidth - addition)));
  }, [maxItemWidth]);

  const updatePanAndZoom = throttle((addition: number) => {
    const cantZoom = tapeStatus === TapeStatus.VIEW;

    if (cantZoom) return;

    const isZooming = !!addition;

    if (isZooming) {
      const isZoomingIn = addition > 0;
      const absAddition = Math.abs(addition);

      setIsInteractive(false);
      setTimeout(() => {
        setIsInteractive(true);
      }, 500);

      zoom(absAddition, isZoomingIn);
    }
  }, 60);

  const handlePlusClick = useCallback(() => {
    updatePanAndZoom(50);
  }, [updatePanAndZoom]);

  const handleMinusClick = useCallback(() => {
    updatePanAndZoom(-50);
  }, [updatePanAndZoom]);

  const handlePrevShizumClick = useCallback(() => {
    store.set.prevShizum();
  }, []);

  const handleNextShizumClick = useCallback(() => {
    store.set.nextShizum();
  }, []);

  const handleMouseEnterShizum = useCallback((shizum: Shizum) => {
    setIsSpotlight(true);
    window.clearTimeout(hoverTimeout.current);
    if (inDiscoverMode && isInteractive) {
      setHoveredShizum(shizum);
      playTrack(shizum.song.url);
    }
  }, [inDiscoverMode, isInteractive]);

  const handleMouseLeaveShizum = useCallback(() => {
    setIsSpotlight(false);
    if (inDiscoverMode) {
      hoverTimeout.current = window.setTimeout(() => {
        setHoveredShizum(undefined);
        pauseTrack();
      }, 20);
    }
  }, [inDiscoverMode]);

  const handleShizumClick = useCallback((shizum: Shizum) => {
    store.set.currentShizum(shizum);

    if (tapeStatus === TapeStatus.VIEW) {
      store.set.tapeStatus(TapeStatus.DISCOVER);
    } else {
      store.set.tapeStatus(TapeStatus.VIEW);
    }
  }, [tapeStatus]);

  const handleBack = useCallback(() => {
    handleShizumClick(currentShizum!);
    setHoveredShizum(undefined);
  }, [currentShizum, handleShizumClick]);

  const animateToCurrentShizum = useCallback((zoomToItem = true) => {
    const newLandscapeItemWidth = maxItemWidth + (zoomToItem ? maxItemAddition : 0);
    const newPortraitItemWidth = (newLandscapeItemWidth / VIDEO_RATIO) / VIDEO_RATIO;

    const index = store.get.currentShizumIndex();
    const numberOfPortraits = store.get.numberOfPortraits();
    const numberOfLandscapes = index - numberOfPortraits;
    const initialOffset = numberOfPortraits * newPortraitItemWidth + numberOfLandscapes * newLandscapeItemWidth;

    const { portrait } = store.get.currentShizum()!;
    const centerOffset = (window.innerWidth - (portrait ? newPortraitItemWidth : newLandscapeItemWidth)) / 2;

    setAnimateToX(initialOffset - centerOffset);
    if (zoomToItem) {
      setItemWidth(newLandscapeItemWidth);
    }
  }, [maxItemWidth]);

  const animateFromCurrentShizum = useCallback(() => {
    const newLandscapeItemWidth = MID_ITEM_WIDTH;
    const newPortraitItemWidth = (newLandscapeItemWidth / VIDEO_RATIO) / VIDEO_RATIO;

    const index = store.get.currentShizumIndex();
    const numberOfPortraits = store.get.numberOfPortraits();
    const numberOfLandscapes = index - numberOfPortraits;
    const initialOffset = numberOfPortraits * newPortraitItemWidth + numberOfLandscapes * newLandscapeItemWidth;

    const { portrait } = store.get.currentShizum() ?? {};
    const centerOffset = (window.innerWidth - (portrait ? newPortraitItemWidth : newLandscapeItemWidth)) / 2;

    const maxOffset = (numberOfAllPortraits * newPortraitItemWidth + numberOfAllLandscapes * newLandscapeItemWidth) - window.innerWidth;
    const offset = Math.min(initialOffset - centerOffset, maxOffset);
    const normalizedOffset = Math.max(0, offset);
    setAnimateToX(normalizedOffset);
    setItemWidth(MID_ITEM_WIDTH);
  }, [numberOfShizums]);

  useEffect(() => {
    if (tapeStatus === TapeStatus.VIEW) {
      animateToCurrentShizum(prevTapeStatus === TapeStatus.DISCOVER);
    } else if (prevTapeStatus !== tapeStatus) { // tapeStatus === TapeStatus.DISCOVER
      animateFromCurrentShizum();
    }

    setIsInteractive(false);
    setTimeout(() => {
      setIsInteractive(true);
    }, 500);
  }, [tapeStatus, currentShizum]);

  useEffect(() => {
    setAnimateToX(undefined);
  }, [animateToX]);

  const handlePanChange = useCallback((offset: number) => {
    const progress = offset / (maxTapeWidth - (containerRef.current?.clientWidth ?? 0));
    store.set.panProgress(progress * 100);
  }, [itemWidth, numberOfShizums]);

  return {
    animateToX,
    containerRef,
    handleBack,
    handleMinusClick,
    handleMouseEnterShizum,
    handleMouseLeaveShizum,
    handleNextShizumClick,
    handlePanChange,
    handlePlusClick,
    handlePrevShizumClick,
    handleShizumClick,
    hoveredShizum,
    inViewMode,
    inDiscoverMode,
    isFirst,
    isInteractive,
    isLast,
    isSpotlight,
    itemHeight,
    itemWidth,
    maxItemHeight,
    maxItemWidth,
    shizums,
    shizumInSpotlight,
    shouldCompensateForZoom,
    zoomAnimation,
  };
};
