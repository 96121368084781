import React from 'react';
import { Box, useTheme } from '@mui/material';

import { DOT_OFFSET, DOT_SIZE } from './infra/constants';

export function SelectionDot() {
  const { palette } = useTheme();

  return (
    <Box
      margin="auto"
      position="relative"
    >
      <Box
        width={DOT_SIZE}
        height={DOT_SIZE}
        position="absolute"
        top={-(DOT_OFFSET)}
        left="50%"
        borderRadius="100%"
        sx={{ backgroundColor: palette.primary.main }}
        zIndex={9999}
      />
    </Box>
  );
}
